import { ChangeDetectionStrategy, Component, effect, Input, OnDestroy, signal, ViewEncapsulation } from "@angular/core";
import { S25IconModule } from "../s25-icon/s25.icon.module";

@Component({
    selector: "s25-ng-fading-success-msg",
    template: `
        <div class="success-msg" [class.show-msg]="isVisible()">
            <s25-ng-icon [type]="'check'"></s25-ng-icon>
            <span class="c-margin-left--quarter">{{ message() }}</span>
        </div>
    `,
    styles: `
        .success-msg {
            opacity: 0;
            transition: opacity 0.5s ease-out;
            color: green;
        }

        .success-msg span {
            position: relative;
            top: 2px;
        }

        .show-msg {
            opacity: 1;
        }
    `,
    standalone: true,
    imports: [S25IconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25FadingSuccessMsgComponent implements OnDestroy {
    @Input() message = signal<string>("");
    @Input() durationInMS: number = 3000;

    isVisible = signal<boolean>(false);
    private timeout: NodeJS.Timeout;

    constructor() {
        effect(
            () => {
                if (this.message()) {
                    this.startTimer();
                }
            },
            { allowSignalWrites: true },
        );
    }

    startTimer() {
        this.isVisible.set(true);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.isVisible.set(false);
        }, this.durationInMS);
    }

    ngOnDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
}
